import {useContext, useMemo, useRef, useState} from 'react';
import {PortalConfigContext} from '../../../../../config/portal';
import {yieldToMain} from '../../../../../utils';
import {useDispatch} from 'react-redux';
import {setGenericEventClean, setSearchTrackingClean} from '../../../../../store/actions/dataLayer';
import classnames from 'classnames';
import {useIntl} from 'react-intl';
import {getCurrencyFormat, getCurrencySymbol} from '../../../../../utils/uomHelper';
import {convertPriceToString} from '../../Filter/FilterSections/MinMaxInput';

export const SRP_LITERALS = {
  MIN: 'min',
  MAX: 'max',
  CURRENCIES: [
    {text: 'USD'},
    {text: 'DKK'},
    {text: 'EUR'},
    {text: 'AUD'},
    {text: 'GBP'},
    {text: 'NOK'},
    {text: 'SEK'}
  ],
  keyWord: {
    min: 'minimum',
    max: 'maximum'
  }
};
const BLUR = 'blur';
const DEFAULT_DELAY = 650;

const useMinMaxLabels = (isThreeColumnLayout, messages, filterName) => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const filterNames = {
    length: {
      min: messages.lengthMin,
      max: messages.lengthMax
    },
    year: {
      min: messages.yearMin,
      max: messages.yearMax
    },
    price: {
      min: messages.priceMin,
      max: messages.priceMax
    }
  };
  const message = filterNames[filterName];
  const ob = useMemo(() => {
    const min = isThreeColumnLayout ? t(messages.quickSearch.min) : t(message.min);
    const max = isThreeColumnLayout ? t(messages.quickSearch.max) : t(message.max);
    return {min, max};
  }, [isThreeColumnLayout]);
  return {
    placeholderMin: ob.min,
    placeholderMax: ob.max,
    titleMin: t(filterNames[filterName].min),
    titleMax: t(filterNames[filterName].max),
    messageTo: t(messages.to)
  };
};

export const defaultGetMinMax = (ref) => {
  const value = ref.current?.getInputValue();

  if (!value) {
    return '';
  }

  return convertPriceToString(value);
};

const useMinMaxTypers = (handleDataChange, filterNameKey, getMinMax, prevMin, prevMax) => {
  const minRef = useRef(null);
  const maxRef = useRef(null);
  const dispatch = useDispatch();
  const [disabledMinMax, setDisabledMinMax] = useState(null);
  const onType = (type) => {
    setDisabledMinMax(type);
  };
  const onTypeEnd = async (type) => {
    const min = getMinMax(minRef);
    const max = getMinMax(maxRef);
    // prevents sending request if same values entered
    if (type === 'min' && min === prevMin || type === 'max' && max === prevMax) {
      setDisabledMinMax(null);
      return;
    }

    const minMax = { min, max };
    dispatch({type: 'GET_DATA_LOADING'});
    await yieldToMain();
    handleDataChange(filterNameKey, minMax);
    await yieldToMain();
    trackMinMaxFacets(type, minMax, filterNameKey);
    setDisabledMinMax(null);
  };
  return {onType, onTypeEnd, disabledMinMax, minRef, maxRef};
};

const useDelayedTyping = (onType, onEndType, delay = DEFAULT_DELAY) => {
  const timeoutRef = useRef(null);

  const onStopTyping = async (type, value, event) => {
    const eventType = event.type;
    await yieldToMain();
    onType(type, value, event.target);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (eventType === BLUR) {
      onEndType(type, value);
    } else {
      timeoutRef.current = setTimeout(() => {
        onEndType(type, value);
      }, delay);
    }
  };
  return {timeoutRef, onStopTyping};
};

const facetAdded = (facet) => {
  setTimeout(() => {
    setSearchTrackingClean(facet, 'single', 'guided search');
  });
};

const facetRemoved = (facet) => {
  setTimeout(() => {
    setGenericEventClean('site search', 'guided search', facet);
  });
};

const trackMinMaxFacets = (type, minMax, trackingKey) => {
  const value = minMax[type];
  if (value) {
    const facetStruct = {[trackingKey]: {[type]: value}};
    facetAdded(facetStruct);
  } else {
    const minMaxWord = SRP_LITERALS.keyWord[type];
    const message = `${minMaxWord} ${trackingKey} removed`;
    facetRemoved(message);
  }
};

const trackMinMaxValuesFromForm = (minMax, trackingKey) => {
  if (trackingKey === 'length' || trackingKey === 'price') {
    minMax = {
      min: minMax.min === '' ? '0' : minMax.min,
      max: minMax.max === '' ? 'Max' : minMax.max
    };
  }
  if (trackingKey === 'year') {
    minMax = {
      min: minMax.min === '' ? 'Min' : minMax.min,
      max: minMax.max === '' ? new Date().getFullYear() + 2 : minMax.max
    };
  }
  const filteredValues = {
    [trackingKey]: {
      min: minMax.min,
      max: minMax.max
    }
  };

  facetAdded(filteredValues);
};

export const getCurrencyCode = (config, intl, userCurrency = null) => {
  if (userCurrency) {
    return userCurrency;
  }
  const currentLocale = intl?.locale;
  if (!currentLocale) {
    return null;
  }
  return config?.languages?.[currentLocale]?.currency?.abbr || null;
};

const handleCurrencyClass = (min, max) => {
  let currencyMinClass;
  let currencyMaxClass;
  let currencyFormat = getCurrencyFormat();

  if (parseInt(min)) {
    currencyMinClass = `currency-symbol-${
      currencyFormat === 'postfix' ? 'suffix' : currencyFormat
    }`;
  } else {
    currencyMinClass = '';
  }

  if (max) {
    currencyMaxClass = `currency-symbol-${
      currencyFormat === 'postfix' ? 'suffix' : currencyFormat
    }`;
  } else {
    currencyMaxClass = '';
  }

  return { currencyMinClass, currencyMaxClass };
};

const useCurrency = (userCurrency, min, max) => {
  const config = useContext(PortalConfigContext);
  const intl = useIntl();
  let { currencyMinClass, currencyMaxClass } = useMemo(() => handleCurrencyClass(min, max), [min, max]);
  const [currencyKey, setCurrencyKey] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState(getCurrencyCode(config, intl, userCurrency) || 'EUR');

  let isUsersCurrencyEnabled = !!config?.supports?.isUsersCurrencyEnabled;
  const smallMinClass = classnames({
    small: isUsersCurrencyEnabled,
    [currencyMinClass]: !isUsersCurrencyEnabled && currencyMinClass
  });
  const smallMaxClass = classnames({
    small: isUsersCurrencyEnabled,
    [currencyMaxClass]: !isUsersCurrencyEnabled && currencyMaxClass
  });
  const currencySymbol = useMemo(() => getCurrencySymbol(), [userCurrency]);

  return {isUsersCurrencyEnabled, currencyKey, setCurrencyKey, smallMinClass, smallMaxClass, selectedCurrency, setSelectedCurrency, currencySymbol};
};

export {
  useMinMaxLabels,
  useCurrency,
  useDelayedTyping,
  useMinMaxTypers,
  trackMinMaxValuesFromForm
};
