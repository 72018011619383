import React, {useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';

export const convertStringToNumber = (string) => {
  return Number(string.replace(/[^0-9.]+/g, ''));
};

export const convertPriceToString = (price) => {
  return String(convertStringToNumber(price));
};

export const convertToPrice = (value, currency = 'USD', locale = 'en-US', ) => {
  const number = convertStringToNumber(value);

  const formattedResult = new Intl.NumberFormat(locale, {
    currency,
    minimumFractionDigits: 0
  }).format(number);

  return `${formattedResult}`;
};

// eslint-disable-next-line react/prop-types
const MinMaxInputRef = ({filterName, placeholder, value, title, prefix, onChange, disabled, cssClass = '', isPrice = false, invalid = false}, ref) => {
  const [inputValue, setInputValue] = useState(value);
  const ariaLabel = `${prefix}-${filterName}-input`;

  const className = invalid ? cssClass + ' invalid-input' : cssClass;

  const setValue = (value) => {
    setInputValue(value);
  };

  const changedInput = (ev) => {
    const newValue = ev.target.value;
    setValue(newValue);
    onChange(prefix, convertPriceToString(newValue), ev);
  };
  useImperativeHandle(ref, () => ({
    getInputValue: () => inputValue
  }));

  useEffect(() => {
    setValue(value);
  }, [value]);

  const getValue = (inputValue) => {
    if (isPrice) {
      if (!inputValue) {
        return '';
      }
      return convertToPrice(inputValue);
    }
    return inputValue;
  };

  const inputTypeProps = isPrice ? {
    type: 'text'
  } : {
    type: "number",
    inputMode: "numeric"
  };

  return (
    <input
      { ...inputTypeProps }
      name={ariaLabel}
      aria-label={ariaLabel}
      className={className}
      placeholder={placeholder}
      value={ getValue(inputValue) }
      onChange={ changedInput }
      onBlur={ changedInput }
      title={title}
      disabled={disabled}
    />
  );
};


const MinMaxInput = forwardRef(MinMaxInputRef);

MinMaxInput.propTypes = {
  filterName: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  title: PropTypes.string,
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  classSize: PropTypes.string,
  cssClass: PropTypes.string,
  disabled: PropTypes.bool,
  isPrice: PropTypes.bool,
  invalid: PropTypes.bool,
};
export {MinMaxInput};
