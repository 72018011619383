import config from '../config/genericConfig.json';
const validEnvs = ['local', 'qa', 'production', 'test'];

class Logger {
  constructor() {
    const nodeEnv = validEnvs.includes(process.env.NODE_ENV) ? process.env.NODE_ENV : 'default';
    this.logLevel = config[nodeEnv].loggingConfig.level;
    // eslint-disable-next-line no-console
    console.log('nodeEnv', nodeEnv);
    // eslint-disable-next-line no-console
    console.log('logLevel', this.logLevel);
  }

  setLogLevel(level) {
    this.logLevel = level;
  }

  log(message, level = 'info', ...optionalParams) {
    const levels = ['error', 'warn', 'info', 'debug'];
    const currentLevelIndex = levels.indexOf(this.logLevel);
    const messageLevelIndex = levels.indexOf(level);

    if (messageLevelIndex <= currentLevelIndex) {
      // eslint-disable-next-line no-console
      console[level](message, ...optionalParams);
    }
  }

  error(message, ...optionalParams) {
    this.log(message, 'error', ...optionalParams);
  }

  warn(message, ...optionalParams) {
    this.log(message, 'warn', ...optionalParams);
  }

  info(message, ...optionalParams) {
    this.log(message, 'info', ...optionalParams);
  }

  debug(message, ...optionalParams) {
    this.log(message, 'debug', ...optionalParams);
  }
}

const logger = new Logger();
export { logger, Logger };
